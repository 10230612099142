import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import { setAuthUser } from "./actions/userActions";
import { auth } from "./utils/firebase";

import Login from "./containers/Login";
import Feedback from "./containers/Feedback";
import ThankYou from "./containers/ThankYou";
import Surveys from "./containers/Surveys";
import SurveyResults from "./containers/SurveyResults";
import AddSurvey from "./containers/AddSurvey";
import NoPermission from "./containers/NoPermission";
import withRoot from "./withRoot";

const PrivateRoute = ({ component: Component, user, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);

    auth.onAuthStateChanged(authUser => {
      authUser
        ? this.props.setAuthUser(authUser)
        : this.props.setAuthUser(null);
    });
  }

  componentDidMount() {
    window.gapi.load("client:auth2", this.initClient);
  }

  initClient = async () => {
    const discoveryDocs = [
      "https://sheets.googleapis.com/$discovery/rest?version=v4"
    ];

    const scope = "https://www.googleapis.com/auth/spreadsheets";

    await window.gapi.client.init({
      apiKey: process.env.REACT_APP_API_KEY,
      clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
      discoveryDocs,
      scope
    });
  };

  render() {
    if (!this.props.user.isInitialized) return null;

    return (
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/surveys"
            component={Surveys}
            user={this.props.user.authUser}
          />
          <PrivateRoute
            path="/surveys/results/:surveyId"
            component={SurveyResults}
            user={this.props.user.authUser}
          />
          <PrivateRoute
            path="/surveys/add"
            component={AddSurvey}
            user={this.props.user.authUser}
          />
          <Route exact path="/" component={Login} />
          <Route path="/feedback/:uniqueId" component={Feedback} />
          <Route path="/thankyou" component={ThankYou} />
          <Route path="/nopermission" component={NoPermission} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  setAuthUser: authUser => dispatch(setAuthUser(authUser))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRoot(App));
