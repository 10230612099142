import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#9DC400",
      main: "#9DC400",
      dark: "#9DC400",
      contrastText: "#fff"
    },
    secondary: {
      light: "#db3802",
      main: "#db3802",
      dark: "#db3802"
    },
    background: {
      default: "#fff"
    }
  },
  typography: {
    useNextVariants: true
  }
});

const withRoot = Component => {
  return props => {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  };
};

export default withRoot;
