import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

export const NoPermission = props => {
  const { classes } = props;
  return <h2 className={classes.text}>No permission</h2>;
};

export default withStyles(styles)(NoPermission);
