import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { firestore } from "../../utils/firebase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Snackbar from "@material-ui/core/Snackbar";
import Icon from "@material-ui/core/Icon";
import styles from "./styles";
import NavBar from "../NavBar";
import CustomSnackbar from "./CustomSnackbar";
import CopyToClipboard from "react-copy-to-clipboard";

export class SurveyResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      survey: null,
      recipients: null,
      isRecipientDialogOpen: false,
      recipientValue: "",
      expanded: null,
      isExporting: false,
      isSnackbarOpen: false,
      spreadsheetContent: null,
      isSurveyLoaded: false,
      deleteConfirmDialogOpen: false,
      surveyRef: null,
      questionRefs: null,
      recipientRefs: null,
      isDeleting: false
    };
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    const surveyRef = await firestore
      .collection("surveys")
      .doc(params.surveyId)
      .get();

    const questionRefs = await surveyRef.ref
      .collection("questions")
      .orderBy("timestamp", "asc")
      .get();

    const questions = questionRefs.docs.map(q => {
      return {
        id: q.id,
        title: q.data().title,
        questionType: q.data().questionType
      };
    });

    const survey = {
      id: surveyRef.id,
      ...surveyRef.data(),
      questions
    };

    const recipientRefs = await firestore
      .collection("recipients")
      .where("surveyId", "==", surveyRef.id)
      .get();

    let recipients = [];

    for (const r of recipientRefs.docs) {
      const feedbackRefs = await r.ref.collection("feedbacks").get();

      let feedbacks = [];

      for (const f of feedbackRefs.docs) {
        feedbacks.push({
          questionId: f.data().questionId,
          rating: f.data().rating,
          text: f.data().text,
          questionType: f.data().questionType
        });
      }

      recipients.push({
        id: r.id,
        email: r.data().email,
        timestamp: r.data().timestamp,
        feedbacks
      });
    }

    recipients.sort((a, b) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });

    this.setState({
      survey,
      recipients,
      isSurveyLoaded: true,
      surveyRef,
      questionRefs,
      recipientRefs
    });
  }

  renderBasicData() {
    const { classes } = this.props;
    const { survey } = this.state;

    if (!survey) return null;

    const date = survey.creationDate.toDate();
    const dateStr = `${date.getDate()}.${date.getMonth() +
      1}.${date.getFullYear()}`;

    const totalAvgRating = this.getTotalAvgRating();

    return (
      <Paper className={classes.basicData}>
        <Grid container spacing={24}>
          <Grid item xs={10}>
            <h1 className={classes.htext}>{survey.title}</h1>
            <h2 className={classes.htext}>{survey.target}</h2>
            <h4 className={classes.htext}>Created {dateStr}</h4>
            <h4 className={classes.htext}>Created by {survey.creatorEmail}</h4>
          </Grid>
          <Grid item xs={2}>
            {totalAvgRating ? (
              <div className={classes.totalAvgRating}>
                <h1 className={classes.totalAvgRatingText}>{totalAvgRating}</h1>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  }

  getTotalAvgRating() {
    const { recipients } = this.state;

    if (recipients.length === 0) return null;

    let totalRating = 0;
    let count = 0;

    for (const recipient of recipients) {
      for (const f of recipient.feedbacks) {
        if (f.questionType === "1" || f.questionType === "1_10") {
          totalRating += Number.parseInt(f.rating);
          count++;
        }
      }
    }

    return totalRating === 0 ? null : Number((totalRating / count).toFixed(2));
  }

  getQuestionAvgRating(questionId) {
    const { recipients } = this.state;

    if (recipients.length === 0) return null;

    let totalRating = 0;
    let count = 0;

    for (const r of recipients) {
      for (const f of r.feedbacks) {
        if (questionId === f.questionId) {
          totalRating += Number.parseInt(f.rating);
          count++;
        }
      }
    }

    return totalRating === 0 ? null : Number((totalRating / count).toFixed(2));
  }

  getRecipientAvgRating(recipient) {
    if (recipient.feedbacks.length === 0) return null;

    let totalRating = 0;
    let count = 0;

    for (const f of recipient.feedbacks) {
      if (f.questionType === "1" || f.questionType === "1_10") {
        totalRating += Number.parseInt(f.rating);
        count++;
      }
    }

    if (count === 0) return null;

    return Number((totalRating / count).toFixed(2));
  }

  renderQuestions() {
    const { classes } = this.props;
    const { survey } = this.state;
    if (!survey) return null;

    return (
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell align="right">Avg Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {survey.questions.map(q => {
              if (q.questionType !== "1" || q.questionType !== "1_10") return null;

              return (
                <TableRow key={q.id}>
                  <TableCell component="th" scope="row">
                    {q.title}
                  </TableCell>
                  <TableCell align="right">
                    {this.getQuestionAvgRating(q.id)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  getRecipientsRatingForQuestion(recipient, question) {
    if (recipient.feedbacks.length === 0) return null;


    for (let f of recipient.feedbacks) {
      if (f.questionId === question.id) {
        switch (f.questionType) {
          case "1":
            return Number(f.rating);
          case "1_10":
            return Number(f.rating);
          case "2":
            return f.text;
          case "3":
            return f.rating === "yes" ? "Kyllä" : "Ei";

          default:
            break;
        }
      }
    }

    return null;
  }

  createSpreadsheet = async () => {
    const { survey } = this.state;

    try {
      const res = await window.gapi.client.sheets.spreadsheets.create({
        properties: {
          title: survey.title + " - " + survey.target
        }
      });

      return res;
    } catch (error) {
      console.log(JSON.stringify(error));
      return null;
    }
  };

  generateDataForSpreadsheet() {
    const { survey, recipients } = this.state;

    let values = [];

    values.push(["Total Avg Rating", this.getTotalAvgRating()]);
    values.push([]);

    for (let q of survey.questions) {
      if (q.questionType !== "1" || q.questionType !== "1_10") continue;

      values.push([q.title, this.getQuestionAvgRating(q.id)]);
    }

    values.push([]);

    for (let r of recipients) {
      const avgRating = this.getRecipientAvgRating(r);
      if (avgRating === null) continue;

      values.push([r.email, avgRating]);

      for (let q of survey.questions) {
        const ratingOrText = this.getRecipientsRatingForQuestion(r, q);

        if ((q.questionType !== "1" || q.questionType !== "1_10") && !ratingOrText) continue;

        values.push([q.title, this.getRecipientsRatingForQuestion(r, q)]);
      }

      values.push([]);
    }

    const body = {
      values: values
    };

    return body;
  }

  getSpreadsheetContent(spreadsheetUrl) {
    return (
      <React.Fragment>
        Data exported.
        <a
          target="_blank"
          href={spreadsheetUrl}
          style={{
            color: "#fff",
            marginLeft: 5
          }}
          rel="noopener noreferrer"
        >
          Open sheet
        </a>
      </React.Fragment>
    );
  }

  exportData = async () => {
    const spreadsheet = await this.createSpreadsheet();
    if (spreadsheet === null) return;

    const { spreadsheetId, spreadsheetUrl } = spreadsheet.result;

    await window.gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId,
      range: "A1",
      valueInputOption: "USER_ENTERED",
      resource: this.generateDataForSpreadsheet()
    });

    const spreadsheetContent = this.getSpreadsheetContent(spreadsheetUrl);

    this.setState({
      isSnackbarOpen: true,
      isExporting: false,
      spreadsheetContent
    });
  };

  updateSigninStatus = isSignedIn => {
    if (isSignedIn) {
      this.exportData();
    }
  };

  exportClick = async () => {
    this.setState({ isExporting: true });

    const authInstance = window.gapi.auth2.getAuthInstance();

    if (authInstance.isSignedIn.get()) {
      this.exportData();
    } else {
      authInstance.isSignedIn.listen(this.updateSigninStatus.bind(this));
      authInstance.signIn();
    }
  };

  generateUrl = id => {
    return process.env.REACT_APP_FEEDBACK_DOMAIN + "/feedback/" + id;
  };

  copyClick = (e, id) => {
    this.setState({
      spreadsheetContent: "Url copied to clipboard",
      isSnackbarOpen: true
    });
  };

  renderRecipients() {
    const { classes } = this.props;
    const { recipients, expanded, survey, isExporting } = this.state;

    if (!recipients) return null;

    return (
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Recipient</TableCell>
              <TableCell align="right">Avg Rating</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {recipients.map(r => (
          <ExpansionPanel
            key={r.id}
            expanded={expanded === r.id}
            onChange={this.handleChange(r.id)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>{r.email}</Typography>
              <Typography className={classes.secondaryHeading}>
                {this.getRecipientAvgRating(r)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Feedback url: {this.generateUrl(r.id)}
                      <CopyToClipboard
                        options={{ message: "Whoa!" }}
                        text={this.generateUrl(r.id)}
                      >
                        <Icon
                          className={classes.copyIcon}
                          style={{ fontSize: 20 }}
                          onClick={e => this.copyClick(e, r.id)}
                        >
                          file_copy
                        </Icon>
                      </CopyToClipboard>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {survey.questions.map(q => (
                    <TableRow key={q.id}>
                      <TableCell component="th" scope="row">
                        {q.title}
                      </TableCell>
                      <TableCell align="right">
                        {this.getRecipientsRatingForQuestion(r, q)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}

        <Button
          onClick={this.openAddRecipientDialog}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Add recipient
        </Button>
        <Button
          onClick={this.exportClick}
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={isExporting}
        >
          Export
        </Button>
        <Button
          onClick={() =>
            this.setState({
              deleteConfirmDialogOpen: true
            })
          }
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          Delete
        </Button>
      </Paper>
    );
  }

  addRecipientClick = async () => {
    const { recipientValue, survey } = this.state;

    if (recipientValue) {
      const timestamp = Date.now();

      const recipientRef = await firestore.collection("recipients").add({
        email: recipientValue,
        surveyId: survey.id,
        isValid: true,
        timestamp
      });

      const newRecipient = {
        id: recipientRef.id,
        email: recipientValue,
        timestamp,
        feedbacks: []
      };

      this.setState(prevState => ({
        isRecipientDialogOpen: false,
        recipients: [...prevState.recipients, newRecipient],
        recipientValue: ""
      }));
    }
  };

  openAddRecipientDialog = () => {
    this.setState({
      isRecipientDialogOpen: true
    });
  };

  closeAddRecipientDialog = () => {
    this.setState({ isRecipientDialogOpen: false, recipientValue: "" });
  };

  renderSnackbar() {
    const { isSnackbarOpen, spreadsheetContent } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={this.handleSnackbarClose}
      >
        <CustomSnackbar
          onClose={this.handleSnackbarClose}
          spreadsheetContent={spreadsheetContent}
        />
      </Snackbar>
    );
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ isSnackbarOpen: false });
  };

  handleDeleteConfirmDialogOpen = () => {
    this.setState({ deleteConfirmDialogOpen: true });
  };

  handleDeleteConfirmDialogClose = () => {
    this.setState({ deleteConfirmDialogOpen: false });
  };

  deleteClick = async () => {
    this.setState({ isDeleting: true });

    const { surveyRef, questionRefs, recipientRefs } = this.state;

    for (var q of questionRefs.docs) {
      await surveyRef.ref
        .collection("questions")
        .doc(q.id)
        .delete();
    }

    await surveyRef.ref.delete();

    for (const r of recipientRefs.docs) {
      const feedbackRefs = await r.ref.collection("feedbacks").get();

      for (const f of feedbackRefs.docs) {
        f.ref.delete();
      }

      r.ref.delete();
    }

    this.props.history.push("/surveys");
  };

  renderDeleteConfirmDialog() {
    return (
      <Dialog
        open={this.state.deleteConfirmDialogOpen}
        onClose={this.handleDeleteConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogActions>
          <Button onClick={this.handleDeleteConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.deleteClick} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderAddRecipientDialog() {
    return (
      <Dialog
        fullWidth={true}
        open={this.state.isRecipientDialogOpen}
        onClose={this.closeAddRecipientDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"> Add Recipient</DialogTitle>
        <DialogContent>
          <TextField
            id="standard-dense"
            autoFocus
            margin="normal"
            label="Recipient"
            type="email"
            fullWidth
            value={this.state.recipientValue}
            onChange={e =>
              this.setState({
                recipientValue: e.target.value
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeAddRecipientDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={this.addRecipientClick} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const { isSurveyLoaded, isDeleting } = this.state;

    if (!isSurveyLoaded || isDeleting) {
      return (
        <div className={classes.layout}>
          <NavBar />
          <CircularProgress className={classes.progress} />
        </div>
      );
    }

    return (
      <div className={classes.layout}>
        <NavBar />
        {this.renderBasicData()}
        {this.renderQuestions()}
        {this.renderRecipients()}
        {this.renderAddRecipientDialog()}
        {this.renderSnackbar()}
        {this.renderDeleteConfirmDialog()}
      </div>
    );
  }
}

export default withStyles(styles)(SurveyResults);
