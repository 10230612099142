const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  root: {
    width: "100%",
    marginTop: 20,
    overflowX: "auto"
  },
  table: {},
  tableRow: {
    cursor: "pointer"
  },
  addSurveyButton: {
    margin: 20
  },
  progress: {
    position: "absolute",
    top: "30%",
    left: "50%",
    color: "#9DC400"
  }
});

export default styles;
