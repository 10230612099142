import React from "react";
import Button from "@material-ui/core/Button";
import FeedbackQuestion from "./FeedbackQuestion";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import urljoin from "url-join";
import styles from "./styles";
import livionImage from "../../images/livionImage.svg";
import surveyImage from "../../images/survey-image-small.jpg";

export class Feedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recipient: null,
      survey: null,
      questions: null,
      isSurveyLoaded: false,
      isSendingFeedbacks: false,
      errorText: null,
      width: window.innerWidth
    };
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props;

    window.addEventListener("resize", this.updateDimensions);

    try {
      var url = urljoin(
        process.env.REACT_APP_API_URL,
        "feedback",
        params.uniqueId
      );

      const res = await axios.get(url);

      this.setState({
        isSurveyLoaded: true,
        recipient: res.data.recipient,
        survey: res.data.survey,
        questions: res.data.questions
      });
    } catch (error) {
      this.setState({
        errorText: error.response.data
      });
      return;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  sendClick = async () => {
    const { questions, recipient, survey } = this.state;

    const feedbacks = [];

    for (var q of questions) {
      if (q.questionType === "1" || q.questionType === "3" || q.questionType === "1_10") {
        if (!q.rating) return;

        feedbacks.push({
          questionId: q.id,
          rating: q.rating,
          questionType: q.questionType
        });
      } else {
        feedbacks.push({
          questionId: q.id,
          text: q.text,
          questionType: q.questionType
        });
      }
    }

    this.setState({ isSendingFeedbacks: true });

    var url = urljoin(process.env.REACT_APP_API_URL, "feedback");

    await axios.post(url, {
      recipientId: recipient.id,
      surveyId: survey.id,
      feedbacks
    });

    this.props.history.push("/thankyou");
  };

  handleChangeValue = e => {
    const questions = this.state.questions.map(q => {
      switch (q.questionType) {
        case "1":
          return q.id === e.target.name ? { ...q, rating: e.target.value } : q;
        case "1_10":
          return q.id === e.target.name ? { ...q, rating: e.target.value } : q;
        case "2":
          return q.id === e.target.name ? { ...q, text: e.target.value } : q;
        case "3":
          return q.id === e.target.name ? { ...q, rating: e.target.value } : q;

        default:
          return null;
      }
    });

    this.setState({ questions });
  };

  renderQuestions() {
    const { questions } = this.state;

    return questions.map(q => {
      return (
        <FeedbackQuestion
          key={q.id}
          id={q.id}
          title={q.title}
          questionType={q.questionType}
          selectedValue={q.rating}
          onChangeValue={this.handleChangeValue}
        />
      );
    });
  }

  render() {
    const { classes } = this.props;
    const {
      isSurveyLoaded,
      isSendingFeedbacks,
      errorText,
      survey
    } = this.state;

    if (errorText) {
      return <h2 className={classes.errorText}>{errorText}</h2>;
    }

    if (!isSurveyLoaded) {
      return <CircularProgress className={classes.progress} />;
    }

    const isWideScreen = this.state.width > 950 ? true : false;

    const livionImageClass = isWideScreen
      ? [classes.livionImage, classes.livionImageWideScreen].join(" ")
      : [classes.livionImage, classes.livionImageNarrowScreen].join(" ");

    const surveyImageClass = isWideScreen
      ? [classes.surveyImage, classes.surveyImageWideScreen].join(" ")
      : [classes.surveyImage, classes.surveyImageNarrowScreen].join(" ");

    const titleClass = isWideScreen
      ? classes.title
      : [classes.title, classes.titleSmallFontSize].join(" ");

    return (
      <div>
        <img className={livionImageClass} src={livionImage} alt="livionImage" />
        <div className={classes.layout}>
          <h1 className={titleClass}>{survey.title}</h1>
          <h3 className={classes.target}>{survey.target}</h3>
          <h3 className={classes.description}>{survey.description}</h3>
          <div className={classes.questionsDiv}>{this.renderQuestions()}</div>
          <div className={classes.buttonDiv}>
            <Button
              onClick={this.sendClick}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isSendingFeedbacks}
            >
              Lähetä
            </Button>
          </div>
        </div>
        <img className={surveyImageClass} src={surveyImage} alt="surveyImage" />
      </div>
    );
  }
}

export default withStyles(styles)(Feedback);
