import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import NavBar from "../NavBar";
import { firestore } from "../../utils/firebase";
import styles from "./styles";

export class Surveys extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      surveys: [],
      page: 0,
      rowsPerPage: 10,
      isPageInitialized: false,
      pageType: 'client',
    };
  }

  async componentDidMount() {
    this.fetchData('surveys');
  }


  fetchData = async (collection) => {
    let surveysRef = null;

    try {
      surveysRef = await firestore.collection(`${collection}`).get();
    } catch (error) {
      this.props.history.push("/nopermission");
      return;
    }

    let surveys = [];

    if (surveysRef.docs.length > 0) {
      for (var sRef of surveysRef.docs) {

        if (this.state.pageType === 'employee' && sRef.data().type === 'employee') {
          const avgRating =
            sRef.data().totalRating /
            sRef.data().feedbackCount /
            sRef.data().questionCount;

          surveys.push({
            id: sRef.id,
            title: sRef.data().title,
            target: sRef.data().target,
            avgRating,
            feedbackCount: sRef.data().feedbackCount
          });

        } else if (this.state.pageType === 'client' && !(sRef.data().type === 'employee')) {
          const avgRating =
            sRef.data().totalRating /
            sRef.data().feedbackCount /
            sRef.data().questionCount;

          surveys.push({
            id: sRef.id,
            title: sRef.data().title,
            target: sRef.data().target,
            avgRating,
            feedbackCount: sRef.data().feedbackCount
          });
        }

      }
    }

    this.setState({
      surveys,
      isPageInitialized: true
    });
  }

  surveyClick = (event, id) => {
    this.props.history.push("/surveys/results/" + id);
  };

  addSurveyClick = () => {
    this.props.history.push("/surveys/add");
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleFeedbackType = (type) => {
    if (type === 'client') {
      this.fetchData('surveys');
      this.setState({ pageType: 'client' });
    }

    if (type === 'employee') {
      this.fetchData('surveys');
      this.setState({ pageType: 'employee' });
    }
  }


  render() {
    const { classes } = this.props;
    const { surveys, rowsPerPage, page, isPageInitialized } = this.state;

    if (!isPageInitialized) {
      return <CircularProgress className={classes.progress} />;
    }
    return (
      <div data-test="testdiv" className={classes.layout}>
        <NavBar />
        <Paper className={classes.root}>
          {this.state.pageType !== "client" && <Button
            onClick={() => this.handleFeedbackType('client')}
            variant="contained"
            color="primary"
            className={classes.addSurveyButton}
          >
            Client Feedback
        </Button>}
          {this.state.pageType !== "employee" && <Button
            onClick={() => this.handleFeedbackType('employee')}
            variant="contained"
            color="primary"
            className={classes.addSurveyButton}
          >
            Employee Feedback
        </Button>}
          <Table className={classes.table}>

            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Target</TableCell>
                <TableCell align="right">Avg Rating</TableCell>
                <TableCell align="right">Feedback count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {surveys
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(survey => {
                  return (
                    <TableRow
                      hover
                      onClick={event => this.surveyClick(event, survey.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={survey.id}
                      className={classes.tableRow}
                    >
                      <TableCell component="th" scope="row">
                        {survey.title}
                      </TableCell>
                      <TableCell>{survey.target}</TableCell>
                      <TableCell align="right">
                        {survey.avgRating ? survey.avgRating.toFixed(2) : ""}
                      </TableCell>
                      <TableCell align="right">
                        {survey.feedbackCount}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={surveys.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Button
            onClick={this.addSurveyClick}
            variant="contained"
            color="primary"
            className={classes.addSurveyButton}
          >
            Add survey
          </Button>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Surveys));
