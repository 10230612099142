import React from "react";
import firebase from "firebase/app";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { connect } from "react-redux";

import NavBar from "../NavBar";
import { firestore } from "../../utils/firebase";
import styles from "./styles";

export class AddSurvey extends React.Component {
  state = {
    title: "",
    target: "",
    description: "",
    type: "client",
    questions: [],
    questionDialogOpen: false,
    questionValue: "",
    questionValueBeforeEdit: "",
    isEditing: false,
    isValidQuestion: true,
    isSavingSurvey: false,
    questionType: "1" //1=numeric rating (1-5), 2=text, 3=yes/no
  };

  saveClick = async () => {
    const { title, target, description, questions, type } = this.state;

    if (
      title === "" ||
      target === "" ||
      description === "" ||
      questions.length === 0 ||
      type === ''
    ) {
      console.log("TODO not valid survey");
      return;
    }

    this.setState({ isSavingSurvey: true });

    let ratingQuestionCount = 0;

    for (const q of questions) {
      if (q.questionType === "1") ratingQuestionCount++;
    }


    this.saveSurvey('surveys', 'questions', ratingQuestionCount);



  };

  saveSurvey = async (col1, col2, ratingQuestionCount) => {
    const { title, target, description, questions, type } = this.state;
    const surveyRef = await firestore.collection(`${col1}`).add({
      title,
      target,
      description,
      creationDate: firebase.firestore.FieldValue.serverTimestamp(),
      creatorEmail: this.props.user.authUser.email,
      totalRating: 0,
      questionCount: ratingQuestionCount,
      feedbackCount: 0,
      type
    });

    for (const q of questions) {
      await surveyRef.collection(`${col2}`).add({
        title: q.title,
        questionType: q.questionType,
        timestamp: q.timestamp
      });
    }

    this.props.history.push("/surveys/results/" + surveyRef.id);
  }

  cancelClick = () => {
    console.log("TODO näytä alert, are you sure?");
    this.props.history.push("/surveys");
  };

  addQuestionClick = e => {
    const {
      questionValue,
      isEditing,
      questions,
      questionValueBeforeEdit,
      questionType
    } = this.state;

    if (questionValue) {
      if (isEditing) {
        const otherQuestions = questions.filter(
          q => q.title !== questionValueBeforeEdit
        );

        let questionAlreadyExists = false;

        for (let q of otherQuestions) {
          if (q.title === questionValue) {
            questionAlreadyExists = true;
            break;
          }
        }

        if (questionAlreadyExists) {
          this.setState({
            isValidQuestion: false
          });
        } else {
          this.setState({
            questionDialogOpen: false,
            questions: questions.map(q =>
              q.title === questionValueBeforeEdit
                ? { ...q, title: questionValue, questionType }
                : q
            ),
            questionValue: ""
          });
        }
      } else {
        let questionAlreadyExists = true;

        for (let q of questions) {
          if (q.title === questionValue) {
            questionAlreadyExists = false;
            break;
          }
        }

        if (!questionAlreadyExists) {
          this.setState({
            isValidQuestion: false
          });
        } else {
          this.setState(prevState => ({
            questionDialogOpen: false,
            questions: [
              ...prevState.questions,
              { title: questionValue, questionType, timestamp: Date.now() }
            ],
            questionValue: ""
          }));
        }
      }
    }
  };

  handleClickOpen = () => {
    this.setState({
      questionType: "1",
      questionDialogOpen: true,
      isEditing: false,
      isValidQuestion: true
    });
  };

  handleClose = () => {
    this.setState({ questionDialogOpen: false, questionValue: "" });
  };

  deleteQuestionClick = (e, question) => {
    const { questions } = this.state;

    this.setState({
      questions: questions.filter(q => q.title !== question.title)
    });
  };

  editQuestionClick = (e, q) => {
    this.setState({
      questionDialogOpen: true,
      questionValue: q.title,
      questionValueBeforeEdit: q.title,
      questionType: q.questionType,
      isEditing: true,
      isValidQuestion: true
    });
  };

  renderBasicData() {
    const { classes } = this.props;
    const { title, target, description } = this.state;

    return (
      <div className={classes.textDiv}>
        <TextField
          id="standard-dense"
          label="Title"
          className={classes.textField}
          margin="normal"
          value={title}
          onChange={e =>
            this.setState({
              title: e.target.value
            })
          }
        />
        <TextField
          id="standard-dense"
          label="Target"
          className={classes.textField}
          margin="normal"
          value={target}
          onChange={e =>
            this.setState({
              target: e.target.value
            })
          }
        />
        <TextField
          id="standard-dense"
          label="Description"
          className={classes.textField}
          margin="normal"
          multiline
          value={description}
          onChange={e =>
            this.setState({
              description: e.target.value
            })
          }
        />
        <RadioGroup
          value={this.state.type}
          onChange={this.onRecipientTypeChange}
        >
          <FormControlLabel value="client" control={<Radio />} label="Client" />
          <FormControlLabel value="employee" control={<Radio />} label="Employee" />
        </RadioGroup>
      </div>
    );
  }

  getTableCellText = questionType => {
    switch (questionType) {
      case "1":
        return "Rating";
      case "2":
        return "Text";
      case "3":
        return "Yes / No";

      default:
        return "";
    }
  };

  renderQuestions() {
    const { classes } = this.props;
    const { questions } = this.state;

    if (questions.length === 0) return null;

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <h2>Questions</h2>
            </TableCell>
            <TableCell>
              <h2>Type</h2>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map(q => (
            <TableRow key={q.title}>
              <TableCell component="th" scope="row">
                {q.title}
              </TableCell>
              <TableCell component="th" scope="row">
                {this.getTableCellText(q.questionType)}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  className={classes.button}
                  aria-label="Delete"
                  onClick={e => this.deleteQuestionClick(e, q)}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  className={classes.button}
                  aria-label="Edit"
                  onClick={e => this.editQuestionClick(e, q)}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  onQuestionTypeChange = event => {
    this.setState({ questionType: event.target.value });
  };

  onRecipientTypeChange = event => {
    this.setState({ type: event.target.value });
  }

  renderDialog() {
    const {
      questionDialogOpen,
      isEditing,
      isValidQuestion,
      questionValue,
      questionType
    } = this.state;

    return (
      <Dialog
        fullWidth={true}
        open={questionDialogOpen}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {isEditing ? "Edit question" : "Add Question"}
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">Type</FormLabel>
            <RadioGroup
              value={questionType}
              onChange={this.onQuestionTypeChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Rating 1-5" />
              <FormControlLabel value="1_10" control={<Radio />} label="Rating 1-10" />
              <FormControlLabel value="2" control={<Radio />} label="Text" />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Yes / No"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="standard-dense"
            error={!isValidQuestion}
            autoFocus
            margin="normal"
            label="Question"
            type="email"
            fullWidth
            value={questionValue}
            onChange={e =>
              this.setState({
                questionValue: e.target.value
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.addQuestionClick} color="primary">
            {isEditing ? "Edit" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const { isSavingSurvey } = this.state;


    return (
      <div className={classes.layout}>
        <NavBar />
        <Paper className={classes.root}>
          {this.renderBasicData()}
          {this.renderQuestions()}
          <div className={classes.buttonDiv}>
            <Button
              onClick={this.handleClickOpen}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Add question
            </Button>
          </div>
          <div align="right" className={classes.buttonDiv}>
            <Button
              onClick={this.cancelClick}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Cancel
            </Button>
            <Button
              onClick={this.saveClick}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isSavingSurvey}
            >
              Save
            </Button>
          </div>
        </Paper>
        {this.renderDialog()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(withStyles(styles)(AddSurvey));
