import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { auth, googleAuthProvider } from "../../utils/firebase";
import { connect } from "react-redux";
import { setAuthUser } from "../../actions/userActions";
import styles from "./styles";
import GoogleButton from "react-google-button";

export class Login extends React.Component {
  signInClick = async () => {
    try {
      await auth.signInWithPopup(googleAuthProvider);
      this.props.history.push("/surveys");
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.centered}>
        <GoogleButton onClick={this.signInClick} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => ({
  setAuthUser: authUser => dispatch(setAuthUser(authUser))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
