const styles = theme => ({
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  googleIcon: {
    width: 20,
    height: 20
  }
});

export default styles;
