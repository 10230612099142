const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  htext: {
    color: "#3c3c3c"
  },
  basicData: {
    padding: 20,
    marginTop: theme.spacing.unit * 3
  },
  paper: {
    marginTop: theme.spacing.unit * 3
  },
  table: {},
  tableEmail: {
    cursor: "pointer"
  },
  button: {
    margin: 10
  },
  totalAvgRating: {
    borderStyle: "solid",
    borderRadius: "15px",
    borderWidth: "1px",
    borderColor: "#ccc"
  },
  totalAvgRatingText: {
    textAlign: "center",
    color: "#3c3c3c"
  },
  heading: {
    flexBasis: "50%",
    flexShrink: 0
  },
  secondaryHeading: {
    flexBasis: "50%",
    textAlign: "right"
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%"
  },
  copyIcon: {
    marginLeft: 10,
    '&:hover': {
      color: "#9d9d9d",
      cursor: "pointer"
    },
  }
});

export default styles;
