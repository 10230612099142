import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

export const FreeText = props => {
  const { onChangeValue, id, classes, title } = props;

  return (
    <div className={classes.questionDiv}>
      <h3 className={classes.title}>{title}</h3>
      <FormControl className={classes.textField}>
        <InputLabel
          htmlFor="custom-css-standard-input"
          classes={{
            root: classes.cssLabel,
            focused: classes.cssFocused
          }}
        />
        <Input
          id="custom-css-standard-input"
          classes={{
            underline: classes.cssUnderline
          }}
          name={id}
          onChange={onChangeValue}
          multiline
        />
      </FormControl>
    </div>
  );
};

export const RadioButtons = props => {
  const { selectedValue, id, onChangeValue, classes, title } = props;

  return (
    <div className={classes.questionDiv}>
      <h3 className={classes.title}>{title}</h3>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td>
              <h6 style={{ paddingTop: 5 }} className={classes.radioText}>
                huonompi
              </h6>
            </td>
            <td />
            <td />
            <td />
            <td>
              <h6 style={{ paddingTop: 5 }} className={classes.radioText}>
                parempi
              </h6>
            </td>
          </tr>

          <tr>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "1"}
                  onChange={onChangeValue}
                  value="1"
                  name={id}
                  aria-label="1"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <Radio
                checked={selectedValue === "2"}
                onChange={onChangeValue}
                value="2"
                name={id}
                aria-label="2"
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            </td>
            <td>
              <Radio
                checked={selectedValue === "3"}
                onChange={onChangeValue}
                value="3"
                name={id}
                aria-label="3"
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            </td>
            <td>
              <Radio
                checked={selectedValue === "4"}
                onChange={onChangeValue}
                value="4"
                name={id}
                aria-label="4"
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            </td>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "5"}
                  onChange={onChangeValue}
                  value="5"
                  name={id}
                  aria-label="5"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <h6 className={classes.radioText}>1</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>2</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>3</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>4</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>5</h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const RadioButtons10 = props => {
  const { selectedValue, id, onChangeValue, classes, title } = props;

  return (
    <div className={classes.questionDiv}>
      <h3 className={classes.title}>{title}</h3>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td>
              <h6 style={{ paddingTop: 5 }} className={classes.radioText}>
                huonompi
              </h6>
            </td>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td>
              <h6 style={{ paddingTop: 5 }} className={classes.radioText}>
                parempi
              </h6>
            </td>
          </tr>

          <tr>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "1"}
                  onChange={onChangeValue}
                  value="1"
                  name={id}
                  aria-label="1"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <Radio
                checked={selectedValue === "2"}
                onChange={onChangeValue}
                value="2"
                name={id}
                aria-label="2"
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            </td>
            <td>
              <Radio
                checked={selectedValue === "3"}
                onChange={onChangeValue}
                value="3"
                name={id}
                aria-label="3"
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            </td>
            <td>
              <Radio
                checked={selectedValue === "4"}
                onChange={onChangeValue}
                value="4"
                name={id}
                aria-label="4"
                classes={{ root: classes.radio, checked: classes.checked }}
              />
            </td>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "5"}
                  onChange={onChangeValue}
                  value="5"
                  name={id}
                  aria-label="5"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "6"}
                  onChange={onChangeValue}
                  value="6"
                  name={id}
                  aria-label="6"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "7"}
                  onChange={onChangeValue}
                  value="7"
                  name={id}
                  aria-label="7"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "8"}
                  onChange={onChangeValue}
                  value="8"
                  name={id}
                  aria-label="8"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "9"}
                  onChange={onChangeValue}
                  value="9"
                  name={id}
                  aria-label="9"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <div style={{ textAlign: "center" }}>
                <Radio
                  checked={selectedValue === "10"}
                  onChange={onChangeValue}
                  value="10"
                  name={id}
                  aria-label="10"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <h6 className={classes.radioText}>1</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>2</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>3</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>4</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>5</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>6</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>7</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>8</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>9</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>10</h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const YesOrNo = props => {
  const { selectedValue, id, onChangeValue, classes, title } = props;

  return (
    <div className={classes.questionDiv}>
      <h3 className={classes.title}>{title}</h3>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td>
              <div style={{ textAlign: "center", paddingTop: 10 }}>
                <Radio
                  checked={selectedValue === "yes"}
                  onChange={onChangeValue}
                  value="yes"
                  name={id}
                  aria-label="yes"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
            <td>
              <div style={{ textAlign: "center", paddingTop: 10 }}>
                <Radio
                  checked={selectedValue === "no"}
                  onChange={onChangeValue}
                  value="no"
                  name={id}
                  aria-label="no"
                  classes={{ root: classes.radio, checked: classes.checked }}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <h6 className={classes.radioText}>Kyllä</h6>
            </td>
            <td>
              <h6 className={classes.radioText}>Ei</h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const FeedbackQuestion = props => {
  const { questionType } = props;

  switch (questionType) {
    case "1":
      return <RadioButtons {...props} />;
    case "1_10":
      return <RadioButtons10 {...props} />;
    case "2":
      return <FreeText {...props} />;
    case "3":
      return <YesOrNo {...props} />;

    default:
      return null;
  }
};

export default withStyles(styles)(FeedbackQuestion);
