const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  buttonDiv: {
    textAlign: "center"
  },
  button: {
    marginTop: 20,
    marginBottom: 40,
    fontFamily: "Montserrat",
    color: "#fff",
    backgroundColor: "#9DC400",
    width: 200,
    "&:hover": {
      background: "#bbe21e"
    }
  },
  progress: {
    position: "absolute",
    top: "30%",
    left: "50%",
    color: "#9DC400"
  },
  errorText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    color: "#9DC400"
  },
  title: {
    textAlign: "center",
    fontFamily: "Montserrat",
    color: "#BFBFBF",
    marginTop: 0,
    marginBottom: 15
  },
  titleSmallFontSize: {
    fontSize: 24
  },
  target: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: "#9DC400",
    marginTop: 0,
    marginBottom: 15
  },
  description: {
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: 0,
    color: "#BFBFBF"
  },
  questionsDiv: {
    marginTop: 100
  },
  livionImage: {
    width: 150,
    marginTop: 10,
    marginBottom: 10
  },
  livionImageWideScreen: {
    marginLeft: 10
  },
  livionImageNarrowScreen: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  },
  surveyImage: {
    width: 300,
    display: "block"
  },
  surveyImageWideScreen: {
    marginLeft: "auto",
    marginRight: "0"
  },
  surveyImageNarrowScreen: {
    marginLeft: "auto",
    marginRight: "auto"
  }
});

export default styles;
