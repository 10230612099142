import { SET_AUTH_USER } from "../actions/types";

const initialState = {
  authUser: null,
  isInitialized: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        ...state,
        authUser: action.authUser,
        isInitialized: true
      };

    default:
      return state;
  }
};
