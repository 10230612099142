const styles = theme => ({
  title: {
    textAlign: "center",
    fontFamily: "Montserrat",
    color: "#9DC400",
    margin: 0
  },
  radio: {
    "&$checked": {
      color: "#9DC400"
    },
    paddingTop: 0,
    paddingBottom: 0
  },
  table: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  checked: {},
  radioText: {
    textAlign: "center",
    fontFamily: "Montserrat",
    color: "#BFBFBF",
    margin: 0
  },
  questionDiv: {
    textAlign: "center",
    paddingBottom: 40
  },
  textField: {    
    marginTop: 10,
    width: "60%"
  },
  cssLabel: {
    "&$cssFocused": {
      color: "#9DC400"
    },
    color: "#BFBFBF",
    fontSize: 15,
    fontFamily: "Montserrat"
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: "#9DC400"
    }
  }
});

export default styles;
