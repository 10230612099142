import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

export const ThankYou = props => {
  const { classes } = props;

  return <h2 className={classes.text}>Kiitos vastauksesta!</h2>;
};

export default withStyles(styles)(ThankYou);
