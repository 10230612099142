const styles = theme => ({
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    color: "#9DC400"
  }
});

export default styles;
